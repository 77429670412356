import React from 'react';

const About = () => {
  return (
    <div name='about' className='w-full h-screen bg-[#0a192f] text-gray-300'>
      <div className='flex flex-col justify-center items-center w-full h-full'>
        <div className='max-w-[1000px] w-full grid grid-cols-2 gap-8'>
          <div className='sm:text-right pb-8 pl-4'>
            <p className='text-4xl font-bold inline border-b-4 border-pink-600'>
              About
            </p>
          </div>
          </div>
          <div className='max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4'>
            <div className='sm:text-right text-2xl font-bold'>
              <p>Here's something about me</p>
            </div>
            <div>
              <p>I am a Junior year student of Vellore Institute of Technology. Being a big fan of Open Source, I have been building my very own community of mentors and students.  I am passionate about building excellent software that improves
              the lives of those around me which ranges from individuals and small-businesses all the
              way to large enterprise corporations.I love contributing to projects and researches and in the process have picked up different technical skills</p>  
            </div>
            
          </div>
          <a href='/About' smooth={true} duration={0}>
          <br></br>click here for community website</a>
      </div>
    </div>
  );
};

export default About;
