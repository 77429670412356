import React from 'react';

import HTML from '../assets/html.png';
import CSS from '../assets/css.png';
import JavaScript from '../assets/javascript.png';
import ReactImg from '../assets/react.png';
import Node from '../assets/node.png';
import FireBase from '../assets/firebase.png';
import AWS from '../assets/aws.png';
import GitHub from '../assets/github.png';
import Tailwind from '../assets/tailwind.png';
import Mongo from '../assets/mongo.png';

const Skills = () => {
  return (
    <div name='Blogs' className='w-full  h-screen bg-[#0a192f] text-gray-300'>
      {/* Container */}
      <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
          <div>
         

              <p className=' text-4xl font-bold inline border-b-4 border-pink-600 '>Blogs</p>
              <p className='py-4'>Some technical and non technical blogs</p>
          </div>

          <div className='w-full  text-center py-8'>
              <div className='bg-[#ccd6f6] mb-2 text-black shadow-md shadow-[#040c16] h-26 hover:scale-110 duration-500'>
              Preventing cookie hijacking with AES 128 symmetric cryptosystem
                 
              </div>
              <div className='bg-[#ccd6f6] mb-2 text-black shadow-md shadow-[#040c16] h-26 hover:scale-110 duration-500'>
               
                 
              </div>
              <div className='bg-[#ccd6f6] mb-2 text-black shadow-md shadow-[#040c16] h-26 hover:scale-110 duration-500'>
              Preventing cookie hijacking with AES 128 symmetric cryptosystem
                 
              </div>
             
          </div>
      </div>
    </div>
  );
};

export default Skills;
