import React from 'react';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { Link } from 'react-scroll';

const Home = () => {
  return (
    <div name='home' className='w-full h-screen text-center bg-[#ccd6f6]'>
      {/* Container */}
      <div className='max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full'>
        <p className='text-pink-600 mb-3'>Hello, Welcome to</p>
        <h1 className='text-4xl sm:text-7xl font-bold text-[#0a192f]'>
         Anisha's Portfolio
        </h1>
        <p className='text-4xl sm:text-xl font-bold text-[#0a192f]'>
           Full Stack Developer
        </p>
        <p className='text-[#0a192f] ml-20 py-4 text-bold'>
        I take a keen interest in building software speacializing in writing neat scalable code.<br></br> I mainly expertise in writing codes in Java backend and React frontend but am always up for facing new challenges and exploring different tech stacks. 
        </p>
        
        <button className='w-52 ml-96 justify-center text-white text-center group border-2  py-3 my-2 flex items-center bg-pink-600 hover:border-pink-600'>
        <Link to='contact' smooth={true} duration={500}>
          Let's get in touch
          </Link>
            <span className='abc'>
              <HiArrowNarrowRight className='ml-3 ' />
            
       

            </span>
            
          </button>
        <div>
         
         
        
          
        </div>
      </div>
    </div>
  );
};

export default Home;
