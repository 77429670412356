import About from "./components/About";
import React, { useState, useEffect } from "react";
import Contact from "./components/Contact";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Blogs from "./components/Blogs";
import Work from "./components/Work";
import Footer from "./components/Footer";
import Preloader from "../src/components/Pre";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
function App() {
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);
  return (
    <Router>
      <Preloader load={load} />
      <div className="App" id={load ? "no-scroll" : "scroll"}></div>
     
        <Navbar />
       
        
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Blogs" element={<Blogs />} />
          <Route path="/About" element={<About />} />
          <Route path="/Work" element={<Work/>} />
          <Route path="/footer" element={<Footer/>} />
          
          <Route path="*" element={<Navigate to="/"/>} />
        </Routes>
    
         
        <Contact />
        <Footer/>

    </Router>



  
  );
}

export default App;
