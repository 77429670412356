import React from 'react';
import WorkImg from '../assets/workImg.png';
import realEstate from '../assets/realEstate.png';

const Work = () => {
  return (
    <div name='work' className='w-full  text-gray-300 bg-[#0a192f]'>
      <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
        <div className='pb-8'>
          <p className='text-4xl font-bold inline border-b-4 text-gray-300 border-pink-600'>
            Work
          </p>
          <p className='py-6'>Check out some of my recent works</p>
        </div>

{/* Container */}
        <div className='pp'>

            <div className='border-solid border-2 border-sky-500 bg-[#ccd6f6]  text-black'>
             
              <div className='pt-8 text-center text-bold text-2xl text-black'>
              Reverse Proxy - Imagine Cup 2022 Runners up</div>
              <div className='pt-8 text-center text-bold ml-2'></div>
              Reverse Proxy is a seamless student attendance management system, leveraging the power of public key cryptosystem and FIDO2 to eliminate malpractices like proxy attendance.
              <div className='pt-8 text-center text-bold text-2xl  text-black'>
                <a href='/'>
                  <button className='border-solid border-2 border-[#0a192f] text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Read More
                  </button>
                </a>
           
            </div>
          </div>
          <div className='border-solid border-2 border-sky-500 bg-[#ccd6f6]  text-black'>
             
             <div className='pt-8 text-center text-bold text-2xl text-black'>
             Media Through Waves-Smart India Hackathon 2022 Winner</div>
             <div className='pt-8 text-center text-bold ml-2'></div>
             Reverse Proxy is a seamless student attendance management system, leveraging the power of public key cryptosystem and FIDO2 to eliminate malpractices like proxy attendance.
             <div className='pt-8 text-center text-bold text-2xl  text-black'>
               <a href='/'>
                 <button className='border-solid border-2 border-[#0a192f] text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                   Read More
                 </button>
               </a>
          
           </div>
         </div>
         <div className='border-solid border-2 border-sky-500 bg-[#ccd6f6]  text-black'>
                
             <div className='pt-8 text-center text-bold text-2xl text-black'>
            
             FIDO Payments - IONathon 2022 Top 10  </div>
             <div className='pt-8 text-center text-bold ml-2'></div>
             Reverse Proxy is a seamless student attendance management system, leveraging the power of public key cryptosystem and FIDO2 to eliminate malpractices like proxy attendance.
             <div className='pt-8 text-center text-bold text-2xl  text-black'>
               <a href='/'>
                 <button className='border-solid border-2 border-[#0a192f] text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                   Read More
                 </button>
               </a>
          
           </div>
         </div>
         



         <div className='border-solid border-2 border-sky-500 bg-[#ccd6f6]  text-black'>
             
             <div className='pt-8 text-center text-bold text-2xl text-black'>
             Athena - Azure Women Hackathon 2022 Finalist </div>
             <div className='pt-8 text-center text-bold ml-2'></div>
             Reverse Proxy is a seamless student attendance management system, leveraging the power of public key cryptosystem and FIDO2 to eliminate malpractices like proxy attendance.
             <div className='pt-8 text-center text-bold text-2xl  text-black'>
               <a href='/'>
                 <button className='border-solid border-2 border-[#0a192f] text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                   Read More
                 </button>
               </a>
          
           </div>
         </div>


        </div>
        </div>
    </div>
  );
};

export default Work;
