import React from 'react';
import {
  FaBars,
  FaTimes,
  FaGithub,
  FaLinkedin,
  FaFacebook,
  FaLinkedinIn,
} from 'react-icons/fa';

const Footer = () => {
  return (
    <div  className='text-center border-t-4  border-black text-lg-start text-muted'>
    
  
      <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
        © 2022 Copyright:Anisha Ghosh
       
      </div>
    </div>
  );
}

export default Footer